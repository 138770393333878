import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useSelector, useDispatch } from 'react-redux';
import { showAuthUser } from 'src/store/api/user';
import { styled, useTheme } from '@mui/material/styles';
import { Paper, Box, Container, Grid, Avatar, Typography, IconButton, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import UserSideName from './components/UserSideNav';
import Page from '../../../components/Page';
import { APPBAR_DESKTOP } from 'src/constants/theme';
import EditIcon from '@mui/icons-material/Edit';
import { mapSettings, CurrentLocationCoordinates } from 'src/helpers/LocationHelper';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { getLocationCoords } from 'src/utils/googleMap';
import { setPage } from 'src/store/reducers/registerReport';
import {jwtDecode} from 'jwt-decode';
import API from 'src/config/api'; // Import API
import { toast } from 'react-toastify'; // Import toast

const containerStyle = {
  width: '100%',
  height: `calc(100vh - ${APPBAR_DESKTOP * 5}px)`,
};

const InfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: '15px',
}));

const HeadTypography = styled(Typography)(({ theme }) => ({
  width: '90px',
  marginRight: '20px',
  fontWeight: '600',
  fontSize: '1rem !important',
  [theme.breakpoints.down('md')]: {
    width: '80px',
  },
}));

const BodyTypography = styled(Typography)(({ theme }) => ({
  fontWeight: '500',
  fontSize: '1rem !important',
}));

const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  position: 'absolute !important',
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  background: theme.palette.secondary.main,
  fontSize: '1.2rem',
  '&:hover': {
    background: theme.palette.secondary.main,
  },
}));

const Profile = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const { latitude, longitude } = useSelector((state) => state.reportRegister.data);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [position, setPosition] = useState({
    lat: latitude,
    lng: longitude,
  });

  const [openConfirm, setOpenConfirm] = useState(false); // State for confirmation dialog

  useEffect(() => {
    dispatch(showAuthUser({})); // Fetch user data
  }, [dispatch]);

  useEffect(() => {
    if (user?.id) {
      const decodedHeader = jwtDecode(user?.name);
      setName(decodedHeader?.name);

      const decodedHeader2 = jwtDecode(user?.email);
      setEmail(decodedHeader2?.email);

      const decodedHeader3 = jwtDecode(user?.phone);
      setPhone(decodedHeader3?.phone);
    }
  }, [user]);

  useEffect(() => {
    if (user && user.latitude && user.longitude) {
      setPosition({
        lat: Number(user.latitude),
        lng: Number(user.longitude),
      });
    }
  }, [user]);

  const onLoad = async (Map) => {
    const { latitude: lat, longitude: lng } = await getLocationCoords();
    dispatch(setPage({ latitude: lat, longitude: lng }));
  };

  const handleDeleteAccount = async () => {
    const token = localStorage.getItem('_token'); // Get token from localStorage
    try {
      const response = await API.get('/removeAccount', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        toast.success('Account successfully deleted');
        localStorage.removeItem('_token'); // Clear token
        navigate('/login'); // Redirect to login
      }
    } catch (error) {
      toast.error('Error deleting account. Please try again.');
    }
  };

  return (
    <Page title={user && name}>
      <Fab color="primary" aria-label="add" sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={() => navigate('/report/add')}>
        <AddIcon />
      </Fab>
      <Container sx={{ marginTop: '20px' }}>
        <Grid container spacing={3}>
          <UserSideName />
          <Grid item md={9} xs={12}>
            <Paper
              sx={{
                padding: '30px',
                position: 'relative',
                boxShadow: theme.shadows[4],
              }}
            >
              <Grid container spacing={3}>
                <Grid item md={9} xs={12}>
                  <IconButtonStyle
                    aria-label="edit"
                    component={RouterLink}
                    to="/profile/edit"
                    sx={{
                      right: '15px',
                      top: '15px',
                    }}
                  >
                    <EditIcon />
                  </IconButtonStyle>
                  <InfoBox>
                    <HeadTypography variant="h5" component="h5">Name: </HeadTypography>
                    <BodyTypography variant="h5" component="h5">{user && name}</BodyTypography>
                  </InfoBox>
                  <InfoBox>
                    <HeadTypography variant="h5" component="h5">Email: </HeadTypography>
                    <BodyTypography variant="h5" component="h5">{user && email}</BodyTypography>
                  </InfoBox>
                  <InfoBox>
                    <HeadTypography variant="h5" component="h5">Username: </HeadTypography>
                    <BodyTypography variant="h5" component="h5">{user && user.username}</BodyTypography>
                  </InfoBox>
                  <InfoBox>
                    <HeadTypography variant="h5" component="h5">Phone: </HeadTypography>
                    <BodyTypography variant="h5" component="h5">{user && phone}</BodyTypography>
                  </InfoBox>

                  {/* Delete Account Button */}
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setOpenConfirm(true)}
                    sx={{ marginTop: '20px' }}
                  >
                    Delete Account
                  </Button>

                  {/* Confirmation Dialog */}
                  <Dialog
                    open={openConfirm}
                    onClose={() => setOpenConfirm(false)}
                    aria-labelledby="confirm-delete-title"
                    aria-describedby="confirm-delete-description"
                  >
                    <DialogTitle id="confirm-delete-title">{"Confirm Account Deletion"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="confirm-delete-description">
                        Are you sure you want to delete your account? This action cannot be undone.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setOpenConfirm(false)} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={handleDeleteAccount} color="error" autoFocus>
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Profile;
