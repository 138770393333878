import React, { useState, useEffect } from 'react';
import { Container, Typography, useTheme, Grid, Box, Checkbox } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import GavelIcon from '@mui/icons-material/Gavel';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from 'src/store/reducers/registerReport';

const Page131 = () => {
  const { illegal_mining: checked } = useSelector(state => state.reportRegister.data);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [miningOption, setMiningOption] = useState(checked || 0);

  useEffect(() => {
    setMiningOption(checked || 0);
  }, [checked]);

  const handleMiningOptionChange = (event) => {
    const value = Number(event.target.value);
    setMiningOption(value);
    dispatch(setPage({ illegal_mining: value }));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container>
        <Grid container spacing={2} justifyContent="center" sx={{ paddingY: 0 }} mt={5}>
          <Grid item xs={10} className="pt-5">
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box borderBottom={2} borderColor={theme.palette.warning.main} style={{ marginRight: '5px', width: '20px' }} />
              <Typography variant="h1" align="center" style={{ fontWeight: 'bold', paddingBottom: '5px', fontSize: '24px' }}>
                Illegal Mining
              </Typography>
              <Box borderBottom={2} borderColor={theme.palette.warning.main} style={{ marginLeft: '5px', width: '20px' }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '20px' }}>
            <GavelIcon fontSize='large'/>
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '5%' }}>
              <Typography id="number-picker-label" style={{ paddingBottom: '16px', textAlign: 'center', fontSize: '16px' }}>
                Was illegal mining involved?
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2, pl: 2 }}>
                <Checkbox 
                  checked={miningOption === 0} 
                  value={0} 
                  onChange={handleMiningOptionChange} 
                />
                <Typography variant="h6" sx={{ fontWeight: 'normal', textAlign: 'center' }}>
                  Does Not Apply
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2, pl: 2 }}>
                <Checkbox 
                  checked={miningOption === 1} 
                  value={1} 
                  onChange={handleMiningOptionChange} 
                />
                <Typography variant="h6" style={{ fontWeight: 'normal', px: 2, textAlign: 'left' }}>
                  Attempted Illegal Mining
                  <br />
                  <span style={{ fontSize: '0.8rem' }}>(minerals were not stolen)</span>
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2, pl: 2 }}>
                <Checkbox 
                  checked={miningOption === 2} 
                  value={2} 
                  onChange={handleMiningOptionChange} 
                />
                <Typography variant="h6" style={{ fontWeight: 'normal', px: 2, textAlign: 'left' }}>
                  Illegal Mining
                  <br />
                  <span style={{ fontSize: '0.8rem' }}>(minerals were stolen)</span>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </LocalizationProvider>
  );
};

export default Page131;