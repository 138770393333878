import React, { useState, useEffect } from 'react';
import { Container, Typography, useTheme, Grid, Box, Checkbox, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from 'src/store/reducers/registerReport';
import poaching from 'src/assets/images/Poaching Icon.png';
import { getAnimals } from 'src/config/api';

const Page132 = () => {
  const { poaching: checked, animals_name } = useSelector(state => state.reportRegister.data);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [poachingOption, setpoachingOption] = useState(checked || 0);
  const [animals, setAnimals] = useState([]);
  const [selectedAnimal, setSelectedAnimal] = useState(
    Array.isArray(animals_name) ? animals_name :
    typeof animals_name === 'string' ? animals_name.split(',').map(item => item.trim()) :
    []
  );

  useEffect(() => {
    
    // Fetch animals when component mounts
    const fetchAnimals = async () => {
      try {
        const response = await getAnimals();
        setAnimals(response.data.data);
      } catch (error) {
        console.error('Error fetching animals:', error);
      }
    };
    
    fetchAnimals();
  }, []);

  const handlepoachingOptionChange = (event) => {
    const value = Number(event.target.value);
    setpoachingOption(value);
    dispatch(setPage({ poaching: value }));
  };

  const handleAnimalChange = (event) => {
    const value = event.target.value;
    setSelectedAnimal(value);
    // Update Redux store with selected animal
    dispatch(setPage({ 
        animals_name: value,
    }));
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container>
        <Grid container spacing={2} justifyContent="center" sx={{ paddingY: 0 }} mt={5}>
          <Grid item xs={10} className="pt-5">
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box borderBottom={2} borderColor={theme.palette.warning.main} style={{ marginRight: '5px', width: '20px' }} />
              <Typography variant="h1" align="center" style={{ fontWeight: 'bold', paddingBottom: '5px', fontSize: '24px' }}>
                Poaching
              </Typography>
              <Box borderBottom={2} borderColor={theme.palette.warning.main} style={{ marginLeft: '5px', width: '20px' }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '20px' }}>
              <img style={{ width: '35px', height: '35px' }} src={poaching} alt="img" />
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '5%' }}>
              <Typography id="number-picker-label" style={{ paddingBottom: '16px', textAlign: 'center', fontSize: '16px' }}>
                Was poaching involved?
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2, pl: 2 }}>
                <Checkbox 
                  checked={poachingOption === 0} 
                  value={0} 
                  onChange={handlepoachingOptionChange} 
                />
                <Typography variant="h6" sx={{ fontWeight: 'normal', textAlign: 'center' }}>
                  Does Not Apply
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2, pl: 2 }}>
                <Checkbox 
                  checked={poachingOption === 1} 
                  value={1} 
                  onChange={handlepoachingOptionChange} 
                />
                <Typography variant="h6" style={{ fontWeight: 'normal', px: 2, textAlign: 'left' }}>
                  Attempted Poaching
                  <br />
                  <span style={{ fontSize: '0.8rem' }}>(animals not killed/stolen)</span>
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2, pl: 2 }}>
                <Checkbox 
                  checked={poachingOption === 2} 
                  value={2} 
                  onChange={handlepoachingOptionChange} 
                />
                <Typography variant="h6" style={{ fontWeight: 'normal', px: 2, textAlign: 'left' }}>
                  Poaching
                  <br />
                  <span style={{ fontSize: '0.8rem' }}>(animals were killed/stolen)</span>
                </Typography>
              </Box>
              
              {poachingOption > 1 && (
                <Box sx={{ mt: 3, px: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel id="animal-select-label">Select Animal</InputLabel>
                    <Select
                      multiple
                      value={selectedAnimal}
                      label="Select Animal"
                      onChange={handleAnimalChange}
                        renderValue={(selected) => selected?.join(', ')}
                    >
                      {animals?.map((animal) => (
                        <MenuItem key={animal} value={animal}>
                            <Checkbox checked={selectedAnimal.indexOf(animal) > -1} />
                          {animal}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </LocalizationProvider>
  );
};

export default Page132;