import React, { useState,useEffect } from 'react';
import { Container, Typography,useTheme, Grid, Box, Checkbox, Select, MenuItem } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useDispatch, useSelector } from 'react-redux';
import { setPage} from 'src/store/reducers/registerReport';
import { loadGoogleMaps } from 'src/utils/googleMap';
import ProgressBar from 'src/layouts/Report/ProgressBar';
import  API from "src/config/api";

function Page11() {
  const {robbery:checked,robbery_type:value=[]} = useSelector(state=>state.reportRegister.data);
  
  const dispatch = useDispatch();
  const theme = useTheme();
  const setChecked=(robbery)=>dispatch(setPage({robbery}));
  const setValue=(robbery_type)=>dispatch(setPage({robbery_type}));
  const [robberydata, setrobbery] = useState([]);
  let newvalue;
  // function isJSONParseable(token) {
  //   try {
  //     JSON.parse(token);
  //     return true;
  //   } catch (error) {
  //     return false;
  //   }
  // }
  // if(isJSONParseable(value) & value!="null") {
  //   console.log(value)
  //   const decodedValue = JSON.parse(value).split(',');
    
  //   dispatch(setPage({robbery_type:decodedValue}));
  //   console.log(decodedValue)
  // }
  if(value === null) {
    dispatch(setPage({robbery_type: []}));
  }
  if(typeof value === 'string') {
    const decodedValue= value.split(',');
    dispatch(setPage({robbery_type:decodedValue}));
// console.log(decodedValue)
  }
//   if (typeof value === 'string') {
//     // Remove surrounding double quotes if present
//     newvalue = value.replace(/^"(.*)"$/, '$1');
//     // Convert the string to an array
//     newvalue = newvalue.split(',');
// }
const [selectedValues, setSelectedValues] = useState(value);
  const handleToggle = (value) => () => {
    const currentIndex = selectedValues?.indexOf(value);
    const newSelected = selectedValues!==null ?[...selectedValues]:[];

    if (currentIndex === -1) {
        newSelected.push(value);
    } else {
        newSelected.splice(currentIndex, 1);
    }

    setSelectedValues(newSelected);
    setValue(newSelected);
};



  useEffect(() => {
    // dispatch(setPage({}));
    
    const fetchCarMakeInfo = async () => {
      const response = await API.get("/robbery");
      const result = response.data;
      setrobbery(result.data.map(data => data));
    }
    fetchCarMakeInfo();
  }, []);

  const handleChange = (v) => {
    setChecked(v);
  };
  // console.log(value)
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Container>
            <Grid container spacing={2} justifyContent="center" sx={{ paddingY: 0 }}>
              <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }} mt={5}>
              <Box display="flex" alignItems="center" justifyContent="center">
              <Box borderBottom={2} borderColor={theme.palette.warning.main} style={{ marginRight: '5px', width: '20px' }} />
              <Typography variant="h4" align="center" style={{ fontWeight: 'bold', paddingBottom: '5px', fontSize: '24px' }}>
                  Robbery
                </Typography>
                <Box borderBottom={2} borderColor={theme.palette.warning.main} style={{ marginLeft: '5px', width: '20px' }} />
                </Box>
                <Typography variant="h2" align="center" style={{ fontWeight: 'bold', paddingBottom: '20px', fontSize: '12px' }}>
                (i.e perpetrators did not enter a building and the crime took place outdoors)
                </Typography>
              </Grid>

              <Box sx={{ pl: 8}}>
                <Typography id="number-picker-label" style={{ paddingBottom: '16px', textAlign: 'center', fontSize: '100%' }}>
                  Was robbery involved?
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2 }}>
                    <Checkbox checked={checked==0} value={0} onChange={()=>handleChange(0)} />
                    <Typography variant="h6" sx={{ fontWeight: 'normal', px: 5, textAlign: 'left' }}>
                      Does not apply
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2 }}>
                    <Checkbox checked={checked==1} value={1} onChange={()=>handleChange(1)} />
                    <Typography variant="h6" sx={{ fontWeight: 'normal', px: 5, textAlign: 'left' }}>
                    Attempted Robbery
                      <br />
                      <span sx={{ fontSize: 'sm' }}>(goods were not stolen)</span>
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2 }}>
                    <Checkbox checked={checked==2} value={2} onChange={()=>handleChange(2)} />
                    <Typography variant="h6" sx={{ fontWeight: 'normal', px: 5, textAlign: 'left' }}>
                      Robbery
                      <br />
                      <span sx={{ fontSize: 'sm' }}>(goods were stolen)</span>
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center', my: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'normal', px: 1, pt: 3, pb: 3, textAlign: { md: 'left' } }}>
                      Select stolen goods item from the list below
                    </Typography>
                    <Select
                multiple
                value={selectedValues && selectedValues[0] !== 'null' ? selectedValues : []}

                onChange={() => {}} // Disable default onChange behavior to handle selection via checkboxes
                sx={{ px: 2, width: '50%', borderRadius: 'none', height: '10' }}
                disabled={checked === 0 || checked === 1}
                renderValue={(selected) => selected?.join(', ')}
            >
                {robberydata.map((option) => (
                    <MenuItem key={option} value={option}>
                        <Checkbox
                            checked={selectedValues && selectedValues.indexOf(option) !== -1}
                            onChange={handleToggle(option)}
                        />
                        {option}
                    </MenuItem>
                ))}
            </Select>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Container>
    </LocalizationProvider>
  );
}

export default Page11;