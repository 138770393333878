import React from 'react';
import { Container, Typography,Box } from '@mui/material';
import './pp_styles.css'
const AboutUs = () => {
  return (
    <div style={{ backgroundColor: '' }}>
      <Container sx={{ py: 6 }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.5rem' }}>
            <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', mb: 2 }}>About CrimeChime</Typography>
           
          </div>
         
        </div>
        </Container>
        <Box
      sx={{
        display: 'flex',
        justifyContent: 'center', // Center content horizontally
        paddingX: { xs: '2%', sm: '15%' }, // Disable paddingX on mobile screens
        marginLeft: { xs: 0, sm: '5%' }, // Disable marginLeft on mobile screens
      }}
    >
      <Typography
        variant="body1"
        sx={{
          fontSize: '18px',
          maxWidth: '4000px',
          color: '#333',
          mb: 4,
          lineHeight: 1.5,
          textAlign: 'justify',
          width: '100%', // Ensure typography takes full width within the Box
        }}
      >
        <> 
       <p class="p1">
    CrimeChime is a public crime-reporting register and database allowing persons with RICA-registered South African cell-phone numbers to report crimes within South Africa. Crimes can be reported regardless of whether they occurred 5 minutes ago or 5 years ago. Users complete a standardised form which typically takes less than 5 minutes to complete.
</p>
<br/>
<p class="p1">
    Contributors are asked only to add crimes which: (i) they experienced first-hand; or (ii) are already in the public domain and have been documented by journalists in the press/media; or (iii) have been provided to them directly by a third party who does not have a South African mobile phone number.
</p>
<br/>
<p class="p1">
    Key information for each crime is then made available to the public for viewing either in the “Crime Map” or “Crime Table” and can be filtered as desired. As such, CrimeChime provides both a historic and real-time picture of crimes as they are reported. Users are also able to store locations of personal interest (e.g. their home) and to receive notifications from CrimeChime of new and recent crimes in the vicinity.
</p>
<br/>
<p class="p1">
    CrimeChime’s goal is to improve the reporting and understanding of crime by a broad base of stakeholders in order to support appropriate strategies and deployment of resources.
</p>
<br/>
<p class="p1">
    CrimeChime is owned and operated by G-Chime Solutions Limited, a company incorporated in England and Wales and which is wholly owned by Gemfields Group Limited (“Gemfields”). Gemfields is listed on the Johannesburg Stock Exchange (JSE:GML) and on the London Stock Exchange’s Alternate Investment Market (AIM:GEM).
</p>
</>
            </Typography>
            </Box>
     
    </div>
  );
};

export default AboutUs;

