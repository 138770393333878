import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Iconify from 'src/components/Iconify';
import { styled } from '@mui/material/styles';

import { 
  Menu, 
  MenuItem, 
  IconButton, 
  ListItemIcon, 
  ListItemText,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material';
import { setCrimeIndex, setEdit, setLock, setNearbyReports, setPage } from 'src/store/reducers/registerReport';
import { useDispatch, useSelector } from 'react-redux';
import API from 'src/config/api';
import { getNearbyCrimes } from 'src/store/api/registerReport';
import { toast } from 'react-toastify';  // Assuming you're using react-toastify for notifications

const LinkToEdit = styled(Link)(({ theme }) => ({
    width: '100%',
    display: 'inherit',
    color: 'inherit',
    textDecoration: 'inherit',
})
);

const ActionOptionsTwo = (props) => {
  const ref = useRef(null);
  const register = useSelector(state => state.reportRegister);
  const profile = JSON.parse(localStorage.getItem("profile"))
  const { data, zoom, nearbyData, crimeIndex } = register;
  const { longitude, latitude } = data;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [openDialog, setOpenDialog] = React.useState({
    status: false, 
    id: null 
  });
  const [openRemoveConfirmation, setOpenRemoveConfirmation] = useState(false);
  const {delete_id, edit_url, show_url, map_url, extra_url, add_note, index, report} = props;
  
  const admin = profile?.data?.role_id === 1 ? true : false;
  const [permission, setPermission] = useState(0)

  useEffect(() => {
    props.deleteAction(openDialog)
    setIsOpen(false)
  }, [openDialog])

  const navigate = useNavigate();

  const handleRemoveReport = async () => {
    try {
      const response = await API.get(`/removeReport/${delete_id}`);
      
      // Assuming the API returns a success message or status
      if (response.data.success) {
        toast.success('Report deleted successfully');
        // Optionally, refresh the list or navigate away
        navigate('/reports'); // or dispatch an action to refresh the list
      } else {
        toast.error(response.data.message || 'Failed to delete report');
      }
    } catch (error) {
      console.error('Error deleting report:', error);
      toast.error('An error occurred while deleting the report');
    } finally {
      setOpenRemoveConfirmation(false);
    }
  };

  return(
    <>
    <IconButton ref={ref} onClick={() => setIsOpen(true)}>
      <Iconify icon="eva:more-vertical-fill" />
    </IconButton>

    <Menu
      open={isOpen}
      anchorEl={ref.current}
      onClose={() => setIsOpen(false)}
      PaperProps={{
        sx: { width: 200, maxWidth: '100%' }
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {admin && delete_id !== undefined ? 
      <MenuItem sx={{ color: 'text.secondary' }} 
        onClick={() => setOpenDialog({ status: true, id: delete_id })}>
        <ListItemIcon>
        <Iconify icon="eva:trash-2-outline" />
        </ListItemIcon>
        <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>
      : '' }

     

      {map_url !== undefined ? 
      <MenuItem sx={{ color: 'text.secondary' }}>
        <Box sx={{display:"flex"}} >
          <LinkToEdit to={map_url}>
            <ListItemIcon>
              <Iconify icon="clarity:eye-line" sx={{fontSize : 22}} />
            </ListItemIcon>
            <ListItemText primary="View on map" primaryTypographyProps={{ variant: 'body2' }} />
          </LinkToEdit>
        </Box>
      </MenuItem>
      : '' }

      {show_url !== undefined ? 
      <MenuItem sx={{ color: 'text.secondary' }}>
        <Box sx={{display:"flex"}}>
          <LinkToEdit to={show_url} >
            <ListItemIcon>
              <Iconify icon="clarity:eye-line" sx={{fontSize : 22}} />
            </ListItemIcon>
            <ListItemText primary="See details" primaryTypographyProps={{ variant: 'body2' }} />
          </LinkToEdit>
        </Box>
      </MenuItem>
      : '' }

      {(profile?.data?.role_id ?? "") === "1" && (
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => navigate(`/add_note/${delete_id}`)}>
          <ListItemIcon>
            <Iconify icon="clarity:plus-line" sx={{ fontSize: 22 }} />
          </ListItemIcon>
          <ListItemText primary="Add/Show Note" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      )}

      {extra_url !== undefined ? 
        <MenuItem sx={{ color: 'text.secondary' }}>
          <LinkToEdit to={extra_url.url}>
            <ListItemIcon>
              <Iconify icon={extra_url.icon} sx={{fontSize : 22}} />
            </ListItemIcon>
            <ListItemText primary={extra_url.name} primaryTypographyProps={{ variant: 'body2' }} />
          </LinkToEdit>
        </MenuItem>
        : '' }
    </Menu>

    {/* Confirmation Dialog */}
    <Dialog
      open={openRemoveConfirmation}
      onClose={() => setOpenRemoveConfirmation(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Confirm Report Deletion"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this report? This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenRemoveConfirmation(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={handleRemoveReport} color="error" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
    </>
  )
}

export default ActionOptionsTwo;