import * as Yup from 'yup';
import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { addCorUser } from 'src/store/api/corporateUser';
import { SaveButton } from 'src/components/Button'
import { slugConvertor } from 'src/helpers/StringHelper';
import API from "../../../config/api";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  ListItemText,
  Checkbox,
  Box,
  Chip
} from '@mui/material';

import BreadcrumbNavigator from 'src/components/BreadcrumbNavigator'
import { FormProvider, RHFTextField } from 'src/components/hook-form';

export default function AddCorpUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(['Corp/Group User']);
  const [cor_id, setCor_id] = useState('');
  const [listcorporates, setListCorporates] = useState({ data: [] });

  const UserSchema = Yup.object().shape({
    name: Yup.string().required('User is required'),
    email: Yup.string().required('E-mail is required'),
  });

  useEffect(() => {
    const fetchCorporateInfo = async () => {
      const profile = JSON.parse(localStorage.getItem('profile'));
      if (profile && profile.data && profile.data.corporat_id) {
        const corpId = profile.data.corporat_id;
        setCor_id(corpId);
        
        try {
          const response = await API.get("/corporateList");
          setListCorporates(response.data);
        } catch (error) {
          console.error("Error fetching corporate list:", error);
          setListCorporates({ data: [] });
        }
      }
    };

    fetchCorporateInfo();
  }, []);

  const defaultValues = {
    name: '',
    email: '',
    phone: '',
  };

  const methods = useForm({
    resolver: yupResolver(UserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (formValue) => {
    formValue.slug = slugConvertor(formValue.name);
    formValue.user_roles = userRole;
  
    if (formValue.phone && formValue.phone.startsWith('0')) {
      formValue.phone = formValue.phone.substring(1);
    }
  
    formValue.cor_id = cor_id;
    dispatch(addCorUser({ formValue, navigate }));
  };

  const breadcrumbNavigate = [
    {
      name: "Corporate user",
      link: "/corp-dashboard",
    },
  ];

  const handleRoleChange = (event) => {
    setUserRole([event.target.value]);
  };

  return (
    <Fragment>
      <BreadcrumbNavigator navigate={breadcrumbNavigate} currentPage="Add Corporate User" />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RHFTextField name="name" label="Name" />
          </Grid>
          <Grid item xs={7}>
            <RHFTextField name="email" label="E-mail Address" type="email" />
          </Grid>
          <Grid item xs={5}>
            <RHFTextField name="phone" label="Mobile" />
          </Grid>

          <Grid item xs={7}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="user-role-label" color="form">
                User Role
              </InputLabel>
              <Select
                labelId="user-role-label"
                id="user-role-select"
                color="form"
                value={userRole[0]}
                input={<OutlinedInput label="User Role" />}
                onChange={handleRoleChange}
              >
                <MenuItem value="Corp/Group User">
                  <ListItemText primary="Corp/Group User" />
                </MenuItem>
                <MenuItem value="Corp/Group Admin">
                  <ListItemText primary="Corp/Group Admin" />
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={7}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="corporate-label" color="form">
                Corporate
              </InputLabel>
              <Select
                labelId="corporate-label"
                id="corporate-select"
                color="form"
                value={cor_id}
                input={<OutlinedInput label="Corporate" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {listcorporates.data.map((corporate) => (
                      cor_id === corporate.id ? <Chip key={corporate.id} label={corporate.name} /> : null
                    ))}
                  </Box>
                )}
                disabled
              >
                {listcorporates.data.map((corporate) => (
                  cor_id === corporate.id ? (
                    <MenuItem key={corporate.name} value={corporate.id}>
                      <Checkbox checked={true} color="form" />
                      <ListItemText primary={corporate.name} />
                    </MenuItem>
                  ) : null
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={7}>
            <SaveButton type="submit">Save</SaveButton>
          </Grid>
        </Grid>
      </FormProvider>
    </Fragment>
  );
}